import BioComponent from '../bio/BioComponent'
import BooksComponent from '../books/BooksComponent'
import './home.css'
const HomeComponent = () => {

    return (
        <>
        <div className='header'>
            The Clio Project Series    
        </div>
        <div className="container">
            <div className="images">
                <div>
                    <img alt="The Clio Project" className="image" src="images/CLIO_PROJECT_COVER.jpg"></img>
                    <img alt="Operation Ameliorate" className="image" src="images/OPERATION_AMELIORATE_BOOK2_FRONT.jpg"></img>
                </div>
            </div>
        </div>
        <div className="subContainer">
            Exciting tales of United States Air Force members traveling through time.
        </div>
        <hr></hr>
        <BooksComponent></BooksComponent>
        <hr></hr>
        <BioComponent></BioComponent>
        </>
    )
}

export default HomeComponent
