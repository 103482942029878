import './bio.css'
const BioComponent = () => {

    return (
        <>
        <div className="bio">
            <div className="header">
                Biography
            </div>
            <div>
                <img alt='Mark S. Roberts' className='bioimage' src="images/MARK_S_ROBERTS.jpg"></img>
            </div>
            <div className='biocontent'>
            Mark S. Roberts served in the United States Air Force for twenty years as a security policeman and is an Operation Desert Storm veteran. He holds a bachelor’s degree in administration in criminal justice. Raised in Jacksonville, Florida, Mark has lived and served in locations around the world and in the United States. He currently resides in Green Bay, Wisconsin, with his wife Lori, also a USAF veteran. He has written two books and working on a third.
            </div>
        </div>

        </>
    )
}

export default BioComponent