import './footer.css'
const FooterComponent = () => {
    return (
        <>
        <div className="footerContainer">
            © Mark S. Roberts {new Date().getFullYear()}
        </div>
        </>
    )
}

export default FooterComponent
