import { useCallback } from 'react'
import './nav.css'

interface NavComponentProperties {
    viewChanged: any
}

const NavComponent = (props: NavComponentProperties) => {

    const linkClicked = useCallback((e: any) => {
        props.viewChanged(e.target.id)
    }, [props])

    return (
        <>
            <table className='nav'>
                <tr>
                    <td><button type='button' className='navButton' id='home' onClick={linkClicked}>HOME</button></td>
                    <td><button type='button' className='navButton' id='bio' onClick={linkClicked}>BIOGRAPHY</button></td>
                    <td><button type='button' className='navButton' id='books' onClick={linkClicked}>BOOKS</button></td>
                </tr>
            </table>
        </>
    )
}

export default NavComponent