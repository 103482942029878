import './books.css'
const BooksComponent = () => {
    const clioProjectUrl = 'https://www.amazon.com/Clio-Project-Military-Travel-Story/dp/B07M85TKTR/'
    const operationAmerliorateUrl = 'https://www.amazon.com/Operation-Ameliorate-Clio-Project-Story/dp/B08R96XY8L/'
    return (
        <>
        <div className="books">
            <div className="header">
                Books
            </div>
            <div className="bookContent">
                <div className="bookHeader">
                    The Clio Project
                </div>
                <div className="bookSubHeader">
                    A Military Time Travel Story
                </div>
                <div>
                    <img alt="The Clio Project" className='bookImage' src='images/CLIO_PROJECT_COVER.jpg'></img>
                </div>
                <div className="bookFooter">
                    <a href={clioProjectUrl}>
                        <img alt="Buy the Clio Project on Amazon" src="images/buy-on-amazon-button.png"></img>
                    </a>
                </div>
                <div className="bookDetail">
                Technical Sergeant Scott Rees figures this will be just another hot August day on base for him and his team of United States Air Force security policemen. Little do they know they are about to become subjects in a top-secret time travel experiment, forcing them into a fight for their lives in the middle of the American Civil War.

                Major General Richard Lucas and Chief Master Sergeant Joe Black want more time to test the Clio time machine before putting it into use, but their request falls on deaf ears as impatient government officials push forward. The incompetence of a key technician and a power grab by his senator uncle have thrown the project into chaos, leaving the security policemen caught in the middle.

                Now Rees and his team face a double-barreled race against time to escape the past and almost certain death.
                </div>

            </div>
            <hr></hr>
            <div className="bookContent">
                <div className="bookHeader">
                    Operation Ameliorate
                </div>
                <div className="bookSubHeader">
                    A Clio Project Story
                </div>
                <div>
                    <img alt="Operation Ameliorate" className='bookImage' src='images/OPERATION_AMELIORATE_BOOK2_FRONT.jpg'></img>
                </div>
                <div className="bookFooter">
                    <a href={operationAmerliorateUrl}>
                        <img alt="Buy Operation Ameliorate on Amazon" src="images/buy-on-amazon-button.png"></img>
                    </a>
                </div>
                <div className="bookDetail">
                    US Cavalryman Corporal Andy O'Toole has spent months in isolation since his mistaken journey into the future, missing his format life and the woman he loves
                    as the Air Force ponders what to do with him. Rees, tired of his own inactivity, devises a plan to return O'Toole to his proper timeline. With the help of
                    Chief Master Sergeant Joseph Black, and a sympathetic senator, Rees makes good on his promise to take O'Toole home. Things, however, don't go as planned. 
                    Saddled with a Special Forces captain no one trusts, the plan goes awry, and Rees and his team must fight their way through the bloody battlefields of history,
                    one wrong move threatening to derail the timeline and trapping them forever in the past.
                </div>

            </div>
        </div>
        </>
    )
}

export default BooksComponent