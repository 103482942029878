import './App.css';
import MainComponent from './components/main/MainComponent';

function App() {
  return (
    <MainComponent></MainComponent>
  );
}

export default App;
