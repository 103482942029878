import { useCallback, useMemo, useState } from "react"
import BioComponent from "../bio/BioComponent"
import BooksComponent from "../books/BooksComponent"
import FooterComponent from "../footer/FooterComponent"
import HomeComponent from "../home/HomeComponent"
import NavComponent from "../nav/NavComponent"
import './main.css'
const MainComponent = () => {
    const [view, setView] = useState('home')

    const viewChanged = useCallback((e: any) => {
        setView(e)
    }, [])

    const buildView = useMemo(() => {
        window.scrollTo(0, 0)
        if(view === 'home'){
            return (
                <>
                    <HomeComponent></HomeComponent>
                </>
            )
        } else if(view === 'bio'){
            return (
                <>
                    <BioComponent></BioComponent>
                </>
            )
        } else if(view === 'books'){
            return (
                <>
                    <BooksComponent></BooksComponent>
                </>
            )
        }

    }, [view])

    return (
        <>
        <div className="sticky">
            <div className="header">
                <div>Mark S. Roberts</div>
            </div>
            <NavComponent viewChanged={viewChanged}></NavComponent>
        </div>
        {buildView}
        <FooterComponent></FooterComponent>
        </>
    )
}

export default MainComponent